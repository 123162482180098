<template>
    <div class="property-drawer-parent">
        <v-btn ref="activator" :class="buttonClasses" @click="showProperties()">Connection </v-btn>
        <div ref="drawer" class="connection-property-drawer">
            <v-card v-if="activated">
                <v-card-text>
                    <v-row justify="center">
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>{{ current_connection_suggestion }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-card-text>
                                                <v-row cols="2">
                                                    <!-- Connection properties -->
                                                    <PropertyBlock title="Connection" :spec="spec" @update="updateParameter" />
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                        <v-divider vertical inset></v-divider>
                                        <v-col>
                                            <v-card-text>
                                                <v-row>
                                                    <v-text-field v-model="connectionName" :disabled="!connectionRenameMode" label="Connection Name" class="special">
                                                        <template v-slot:append>
                                                            <v-btn
                                                                v-if="!connectionRenameMode"
                                                                :disabled="connectionRenameMode"
                                                                color="primary"
                                                                icon
                                                                x-small
                                                                depressed
                                                                tile
                                                                class="ma-0"
                                                                @click="connectionRenameMode = true"
                                                            >
                                                                <v-icon>mdi-pencil</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                v-if="connectionRenameMode"
                                                                :disabled="!connectionRenameMode"
                                                                icon
                                                                x-small
                                                                depressed
                                                                tile
                                                                color="green"
                                                                class="ma-0"
                                                                @click="renameConnection"
                                                            >
                                                                <v-icon>mdi-check</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                v-if="connectionRenameMode"
                                                                :disabled="!connectionRenameMode"
                                                                color="red"
                                                                icon
                                                                tile
                                                                x-small
                                                                depressed
                                                                class="ma-0"
                                                                @click="renameConnectionCancel"
                                                            >
                                                                <v-icon>mdi-cancel</v-icon>
                                                            </v-btn>
                                                        </template>
                                                    </v-text-field>
                                                </v-row>
                                                <v-row no-gutters>
                                                    Source:
                                                    <v-col v-for="source in sources" :key="source.name" cols="4">
                                                        <v-chip v-if="chip1" small close color="green" text-color="white" closable @click:close="chip1 = false">{{
                                                            source.name
                                                        }}</v-chip>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    Sinks:
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col v-for="sink in sinks" :key="sink.name" cols="4">
                                                        <v-chip v-if="chip2" small close color="green" text-color="white" @click:close="chip2 = false">{{ sink.name }}</v-chip>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                        <v-divider vertical inset></v-divider>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                Connection Profile
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-select v-model="selectedProfile" :items="connectionProfiles"></v-select>
                                            </v-row>
                                            <v-row>
                                                <v-img max-height="150" max-width="150" src="@/assets/technology/CHANNEL.png" class="image-placeholder"></v-img>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import EventBus from "@/events/events";
import Registry from "@/app/core/registry";
import "@mdi/font/css/materialdesignicons.css";
import "vue-select/dist/vue-select.css";
import Vue from "vue";
import vSelect from "vue-select";
import PropertyBlock from "@/components/base/PropertyBlock.vue";
import { ComponentAPI } from "@/componentAPI";
import {ConnectionToolState} from "@/app/view/tools/connectionTool";

Vue.component("v-select", vSelect);

export default {
    name: "ConnectionPropertyDrawer",
    components: { PropertyBlock },
    icons: {
        iconfont: "mdi"
    },
    data() {
        return {
            connectionName: "NewConnection",
            connectionRenameMode: false,
            spec: this.computedSpec("Connection"),
            chip1: true,
            chip2: true,
            activated: false,
            isEditing: false,
            // connection_suggestions: { state1: "Left Click to Choose a Point", state2: "Right Click to End Connection" },
            // current_connection_suggestion: this.connection_suggestions.state1,
            connectionProfiles: [],
            selectedProfile: "",
            previews: { CHANNEL: "@/assets/technology/CHANNEL.png" },
            activeTool: null
        };
    },
    computed: {
        buttonClasses: function() {
            return [this.activated ? this.activatedColor : "white", this.activated ? this.activatedTextColor : "blue--text", "mx-auto", "my-1", "btn"];
        },

        sinks: function() {
            if(this.activeTool === null) {
                return [];
            }else{
                console.log("sinks", this.activeTool.sinks);
                let ret = [];
                for(let i = 0; i < this.activeTool.sinks.length; i++){
                    let sink = this.activeTool.sinks[i];
                    console.log("sink", sink);
                    ret.push({"name":"test1"});
                }
                return ret;
                //return [{"name":"test1"}, {"name":"test2"}, {"name":"test3"}];

            }
        },

        sources: function() {
            if(this.activeTool === null) {
                return [];
            }else{
            console.log("sources", this.activeTool.sources);
            let ret = [];
                let source = this.activeTool.source;
                console.log("source", source);
                return [{"name":"test1"}];
            }
        },

        current_connection_suggestion: function() {
            const STATE0 = "Unable to test the connection tool state";
            const STATE1 = "Left Click to choose a Start Point";
            const STATE2 = "Left Click to place waypoint, Right Click to end Connection";
            if (this.activeTool === null){
                return STATE0;
            }else{
                if (this.activeTool.state === ConnectionToolState.PLACE_FIRST_POINT){
                    return STATE1;
                }else if(this.activeTool.state === ConnectionToolState.PLACE_WAYPOINT){
                    return STATE2;
                }else if(this.activeTool.state === ConnectionToolState.TARGET_PLACED_START_AGAIN){
                    return STATE1;
                }else{
                    return "Unknown State, suggestion error";
                }
            }
        }

    },
    mounted() {
        // Setup an event for closing all the dialogs
        const ref = this;
        EventBus.get().on(EventBus.CLOSE_ALL_WINDOWS, function() {
            ref.activated = false;
        });
        EventBus.get().on(EventBus.RIGHT_CLICK, this.endConnection);
        // Load the connection profiles
        this.connectionProfiles = ComponentAPI.getConnectionTypes();
        this.selectedProfile = this.connectionProfiles[0];
        
    },
    methods: {
        updateParameter(value, key) {
            this.activeTool.updateParameter(key, value);
        },
        computedSpec: function(threeduftype) {
            // Get the corresponding the definitions object from the componentAPI, convert to a spec object and return
            let definition = ComponentAPI.getDefinition(threeduftype);
            let spec = [];
            for (let key in definition.heritable) {
                console.log(definition.units[key]);
                // const unittext = definition.units[key] !== "" ? he.htmlDecode(definition.units[key]) : "";
                let item = {
                    mint: key,
                    min: definition.minimum[key],
                    max: definition.maximum[key],
                    value: definition.defaults[key],
                    units: definition.units[key],
                    steps: (definition.maximum[key] - definition.minimum[key]) / 10,
                    name: key
                };
                spec.push(item);
            }
            return spec;
        },
        showProperties() {
            this.activated = !this.activated;

            let attachPoint = document.querySelector("[data-app]");

            if (!attachPoint) {
                console.error("Could not find [data-app] element");
            }

            this.setDrawerPosition();
            attachPoint.appendChild(this.$refs.drawer);
            if (this.activated) {
                this.startConnection();
                this.activeTool = Registry.viewManager.tools.Connection;
            } else {
                this.endConnection();
            }

        },
        handleScroll() {
            this.setDrawerPosition();
        },
        setDrawerPosition() {
            if (!this.activated) return;
            const bounds = this.$refs.activator.$el.getBoundingClientRect();
        },
        // openClose() {
        //     this.isOpen = !this.isOpen;
            
        //     console.log("running open/close");

        //     // console.log("Registry:", Registry);
        //     // console.log("View Manager:", Registry.viewManager);
        //     // console.log("Connection Tool:", Registry.viewManager.tools);
        //     // this.activateTool = Registry.viewManager.tools.Connection;
        //     // console.log("Active Tool:");
        //     // console.log(this.activateTool);

        //     // this.sources= function() {
        //     //     if (Registry.viewManager !== undefined || Registry.viewManager !== null) {
        //     //         if (Registry.viewManager.tools.Connection.source !== null) {
        //     //             return [Registry.viewManager.tools.Connection.source];
        //     //         } else {
        //     //             return [];
        //     //         }
        //     //     } else {
        //     //         return [];
        //     //     }

        //     // };
        //     // this.sinks = function() {
        //     //     if (Registry.viewManager !== undefined || Registry.viewManager !== null) {
        //     //         return Registry.viewManager.tools.Connection.sinks;
        //     //     } else {
        //     //         return [];
        //     //     }
        //     // };

        // },
        connectionStatus() {
            this.isEditing = true;
        },
        startConnection() {
            Registry.viewManager.activateTool("Connection", "Connection");
            // this.current_connection_suggestion = this.connection_suggestions["state2"];
        },
        endConnection: function() {
            // this.current_connection_suggestion = this.connection_suggestions["state1"];
            // console.log(this.connection_suggestions["state1"]);
        },
        renameConnection() {
            this.connectionRenameMode = false;
            // TODO: Change the name of the connection in the connection tool
        },
        renameConnectionCancel() {
            this.connectionRenameMode = false;
            // TODO: Cancel renaming the connection in the connection tool, pull the name and apply it to current name
        }
    }
};
</script>

<style lang="scss" scoped>
.property-drawer-parent {
    overflow: visible;
    position: relative;
}

.btn {
    width: 100%;
}

.table {
    width: 480px;
}

.d-inline {
    margin-top: 20px;
    margin-left: 150px;
}

.d-inline:hover {
    cursor: pointer;
}

.subtitle-1 {
    margin-left: 12px;
}

.pencil {
    padding-top: 15px;
    padding-left: 15px;
}

.connection-property-drawer {
    position: absolute;
    float: left;
    width: 1000px;
    left: 225px;
    top: 10px;
    z-index: 100;

    ::v-deep .v-messages {
        display: none;
    }

    td {
        padding: 4px;
    }

    ::v-deep .v-input__slot {
        margin: 12px 0;
    }

    ::v-deep .v-text-field {
        padding-top: 0;
    }

    ::v-deep .v-text-field__details {
        display: none;
    }

    .connection-profile {
        margin-top: 10px;
        margin-left: 20px;
        text-align: center;
    }
}

#dropdown {
    margin-top: 10px;
}

.image-placeholder {
    margin-left: 35px;
    margin-top: 10px;
}
.special.v-text-field.v-input--is-disabled {
    pointer-events: initial;
}
</style>
